@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap');

#root {
    position: relative;
    min-height: 100vh;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    font: 400 14px Ubuntu, sans-serif;
    background: #f7f7f7;
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: column;
}

html, body {
    height: 100%;
    margin: 0;
    scroll-behavior: smooth;
}

.wrapper {
    min-height: 100%;
    margin-bottom: -225px;
}

footer,
.push {
  height: 225px;
}