@import url(https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap);
.animate__animated.animate__bounce {
  --animate-duration: 7s;
}

.first-image img {
  max-width: 100%;
}

.solutions {
  height: 950px;
  background-color: #f7f7f7;
}

.solutions .title {
  background-color: #f7f7f7;
}

.solutions .title h2 {
  color: #191970;
  font: 700 32px Ubuntu, sans-serif;
  text-align: center;
  padding: 20px;
}

.solutions .title .evolution {
  color: #701919;
  font: 700 20px Ubuntu, sans-serif;
  text-align: center;
  padding: 20px;
  margin-bottom: 15px;
}

.solutions .title p {
  color: #000;
  font: 700 20px Ubuntu, sans-serif;
  text-align: center;
  padding: 20px;
  margin-bottom: 30px;
}

.solutions img {
  margin: 10px 10px;
  padding: 5px;
  height: 100px;
}

.solutions button {
    cursor: pointer;
    height: 60px;
    background: #701919;
    border: 0;
    border-radius: 8px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    font-weight: 800;
    line-height: 60px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
    padding: 15px;
}

.solutions button a {
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font: 700 18px Ubuntu, sans-serif;
}

.solutions .buttons { 
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.solutions button:hover{
  -webkit-filter: brightness(90%);
          filter: brightness(90%)
}

.icons {
  margin: auto;
  max-width: 90%;
  display: grid;
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  grid-auto-rows: 90px;
  grid-auto-columns: auto;
  grid-template-areas: 
    "a a b b c c d d e e"
    "a a b b c c d d e e"
    "f f g g h h i i j j"
    "f f g g h h i i j j"
    "k k l l m m n n o o"
    "k k l l m m n n o o";
  align-items: flex-start;
  justify-items: center;
}

.fade-in-section.is-visible {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.financeiro {
  grid-area: a;
}
.comercial {
  grid-area: b;
}
.logistica {
  grid-area: c;
}
.producao {
  grid-area: d;
}
.servico {
  grid-area: e;
}
.fiscal {
  grid-area: f;
}
.controleQualidade {
  grid-area: g;
}
.manutAtivos {
  grid-area: h;
}
.gestaoPessoas {
  grid-area: i;
}
.medicina {
  grid-area: j;
}
.gestaoAmbiental {
  grid-area: k;
}
.gestaoClinica {
  grid-area: l;
}
.universal {
  grid-area: m;
}
.crm {
  grid-area: n;
}
.BI {
  grid-area: o;
}

.icons p {
  max-width: 140px;
  color: #000;
  font: 700 18px Ubuntu, sans-serif;
  text-align: center;
}
header {
  width: 100%;
  max-width: 100%;
  padding: 0 0px;
}

header .links {
  margin-right: 0px;
  display: flex;
  margin-left: auto;
  padding: 4px;
  align-items: center;
  background: #701919;
  width: 100%;
  justify-content: flex-end;
}

header .links a {
  font: 400 14px Ubuntu, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 800;
  margin-right: 20px;
  color: #ededed;
  display: flex;
  align-items: center;
}

header a svg {
  margin-right: 4px;
}

header .links p {
  font: 400 14px Ubuntu, sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  margin-right: 20px;
  color: #ededed;
  display: flex;
  align-items: center;
}

header p svg {
  margin-right: 4px;
}

.navigation-container {
  width: 100%;
  text-align: left;
  background-color: #ededed;
  padding: 10px 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navigation-container img{
  height: 60px;  
  float: left;
  margin-left: 40px;
}

.navigation-container ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  text-align: center;
  display: inline-block;
  vertical-align:top;
}

.navigation-container ul li {
  display: inline-block;
  padding: 0px;
}

.navigation-container ul li a {
  font: 400 17px Ubuntu, sans-serif;
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #000;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0 10px;
}
.navigation-container ul li a,
.navigation-container ul li a:after,
.navigation-container ul li a:before {
  transition: all .5s;
}
.navigation-container ul li a:hover {
  color: #231b70;
}

.navigation-container .stroke ul li a {
  position: relative;
}
.navigation-container .stroke ul li a:after{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #231b70;
  height: 1px;
}

.navigation-container .stroke ul li a:hover:after {
  width: 100%;
}

.menu-separator {
  height: 5px;
  background-color: #191970;
}
footer {
  bottom: 0;
  width: 100%; 
  display: flex;
  flex-direction: column;
  background-color: #ededed;
}

.separator {
  background-color: #191970;
  height: 5px;
}

footer .footerInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
}

footer .footerInfo h3 {
  font: 800 18px Ubuntu, sans-serif;
  text-transform: uppercase;
}

footer .footerInfo ul {
  list-style: none;
  margin-top: 20px;
}

footer .footerInfo ul li {
  margin: 10px 0;
}

footer .footerInfo ul li a {
  text-decoration: none;
  font: 400 16px Ubuntu, sans-serif;
  color: #000;
}

footer .footerInfo ul li a:hover {
  color: #191970;
  text-decoration: underline;
}

footer .footerInfo .logo ,
footer .footerInfo .links  {
  margin-right: 100px;
}

footer .footerInfo .logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer .footerInfo .logo img {
  height: 100px;
}


footer .footerInfo .logo p {
  margin-top: 15px;
  max-width: 210px;
  font: 700 13px Ubuntu, sans-serif;
  text-align: jutify;
}

footer .footerInfo .contact-footer p {
  margin-top: 20px;
  font: 400 16px Ubuntu, sans-serif;
}

footer .footerInfo .contact-footer p + p{
  margin-top: 10px;
  font: 400 16px Ubuntu, sans-serif;
}

footer .footerInfo .contact-footer svg {
  margin-right: 2px;
}

footer .copyright {
  display: flex;
  justify-content: center;
  font: 400 14px Ubuntu, sans-serif;
}
.fade-in-section {
  opacity: 0;
  transform: translateY(5vh);
  visibility: hidden;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
.outside-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;
  background-color: #f7f7f7;
}


.image img {
  max-width: 100%;
}

.solution-icons img {
  margin: 10px 10px;
  padding: 5px;
  height: 120px;
  cursor: pointer;
}

.solution-icons img:hover {
  opacity: 0.7;
}

.outside-container .animate__animated {
  flex: 1 1;
  max-width: 785px;
}

.solution-icons {
  margin-bottom: 58px;
  margin-top: 40px;
  margin-left: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 20px;
}

.solution-icons p {
  max-width: 140px;
  color: #000;
  font: 700 16px Ubuntu, sans-serif;
  text-align: center;
}

.solution-text {
  margin-left: 60px;
  margin-top: 20px;
  width: 450px;
  margin-right: 60px;
  margin-bottom: 20px;
}

.solution-text h2 {
  margin-top: 30px;
  text-transform: uppercase;
  border-bottom: 2px solid #000;
  font: 700 18px Ubuntu, sans-serif;
  width: 100%;
  margin-bottom: 20px;
}

.solution-text h3 {
  color: #701919;
  margin-top: 30px;
  font: 700 16px Ubuntu, sans-serif;
  width: 100%;
  margin-bottom: 20px;
}

.solution-text p {
  text-align: justify;
  font: 400 16px Ubuntu, sans-serif;
  line-height: 24px;
}

.solution-text ul {
  list-style-type: '✓';
  list-style-position: outside;
}

.solution-text ul li {
  margin: 0;
	padding: 4px 0 0 8px;
  text-align: justify;
  font: 400 16px Ubuntu, sans-serif;
  margin: 0 0 10px;
}

.solution-icons article {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.contact .left h1 {
  text-transform: uppercase;
  border-bottom: 2px solid #000;
  font: 700 20px Ubuntu, sans-serif;
  width: 300px;
}

.contact .left  p {
  padding: 2px;
  font: 300 18px Ubuntu, sans-serif;
}

.contact h1 {
  text-transform: uppercase;
  border-bottom: 2px solid #000;
  font: 700 18px Ubuntu, sans-serif;
  width: 300px;
  margin-bottom: 10px;
}

.contact p + h1 {
  text-transform: uppercase;
  border-bottom: 2px solid #000;
  font: 700 18px Ubuntu, sans-serif;
  width: 300px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.contact  p {
  padding: 2px;
  font: 300 16px Ubuntu, sans-serif;
}

.contact  p svg {
  margin-right: 5px;
}

.image img {
  max-width: 100%;
}
.main-container {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.main-container .animate__animated.animate__fadeIn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.main-container .history-content {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 100%;
  width: 70%;
  margin-left: 100px;
  margin-right: 40px;
  background-image: url(/static/media/background.7183300d.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
}

.main-container .circles .items {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  color: #fff;
  line-height: 500px;
  background: #191970;
  padding: 20px;
  margin-right: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.main-container .circles .items p {
  font: 700 16px Ubuntu, sans-serif;
  color: #fff;
  line-height: 30px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.main-container .history-content h1 {
  text-transform: uppercase;
  border-bottom: 2px solid #000;
  font: 700 18px Ubuntu, sans-serif;
  margin-bottom: 16px;
  color: #000;
  text-align: justify;
}
.main-container .history-content h2 {
  text-transform: uppercase;
  border-bottom: 2px solid #000;
  font: 700 18px Ubuntu, sans-serif;
  margin-bottom: 16px;
  color: #000;
  text-align: justify;
  margin-top: 16px;
}

.main-container .history-content h2:first-of-type {
  text-transform: uppercase;
  border-bottom: 2px solid #000;
  font: 700 18px Ubuntu, sans-serif;
  margin-bottom: 16px;
  color: #000;
  text-align: justify;
  margin-top: 32px;
}
.main-container .history-content p {
  font: 300 16px Ubuntu, sans-serif;
  text-align: justify;
}

.linhaTempo {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 90%;
}
#root {
    position: relative;
    min-height: 100vh;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    font: 400 14px Ubuntu, sans-serif;
    background: #f7f7f7;
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: column;
}

html, body {
    height: 100%;
    margin: 0;
    scroll-behavior: smooth;
}

.wrapper {
    min-height: 100%;
    margin-bottom: -225px;
}

footer,
.push {
  height: 225px;
}
