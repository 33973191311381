.main-container {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.main-container .animate__animated.animate__fadeIn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.main-container .history-content {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 100%;
  width: 70%;
  margin-left: 100px;
  margin-right: 40px;
  background-image: url("../../assets/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
}

.main-container .circles .items {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  color: #fff;
  line-height: 500px;
  background: #191970;
  padding: 20px;
  margin-right: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.main-container .circles .items p {
  font: 700 16px Ubuntu, sans-serif;
  color: #fff;
  line-height: 30px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.main-container .history-content h1 {
  text-transform: uppercase;
  border-bottom: 2px solid #000;
  font: 700 18px Ubuntu, sans-serif;
  margin-bottom: 16px;
  color: #000;
  text-align: justify;
}
.main-container .history-content h2 {
  text-transform: uppercase;
  border-bottom: 2px solid #000;
  font: 700 18px Ubuntu, sans-serif;
  margin-bottom: 16px;
  color: #000;
  text-align: justify;
  margin-top: 16px;
}

.main-container .history-content h2:first-of-type {
  text-transform: uppercase;
  border-bottom: 2px solid #000;
  font: 700 18px Ubuntu, sans-serif;
  margin-bottom: 16px;
  color: #000;
  text-align: justify;
  margin-top: 32px;
}
.main-container .history-content p {
  font: 300 16px Ubuntu, sans-serif;
  text-align: justify;
}

.linhaTempo {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 90%;
}