header {
  width: 100%;
  max-width: 100%;
  padding: 0 0px;
}

header .links {
  margin-right: 0px;
  display: flex;
  margin-left: auto;
  padding: 4px;
  align-items: center;
  background: #701919;
  width: 100%;
  justify-content: flex-end;
}

header .links a {
  font: 400 14px Ubuntu, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 800;
  margin-right: 20px;
  color: #ededed;
  display: flex;
  align-items: center;
}

header a svg {
  margin-right: 4px;
}

header .links p {
  font: 400 14px Ubuntu, sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  margin-right: 20px;
  color: #ededed;
  display: flex;
  align-items: center;
}

header p svg {
  margin-right: 4px;
}
